.me-CartIcon {
  cursor: pointer;
  display: flex;
  & > a {
    display: flex;
  }
}
.me-CartPreview{
  max-height:0;
  transition: max-height 0.5s ease;
  overflow: hidden;
  position: absolute;
  z-index: 99;
  max-width: 100%;
  left: 50%;
  transform: translateX(-50%);
  &.show{
   max-height: 100vh;
    overflow-x:hidden;
    overflow-y: auto;
  }
  .button-wrap{margin-top: 10px;}
}
