$COL1: rgba(0,0,0,0); //
$COLFLAT1: #808080; //
$COL2: #f37736; //
$COLFLAT2: #f37736; //
$COL3: #0084ab; //
$COLFLAT3: #0084ab; //
$COL4: #eeeeee; //
$COLFLAT4: #eeeeee; //
$COL5: #b7c2c7; //
$COLFLAT5: #b7c2c7; //
$COL6: #ffffff; //
$COLFLAT6: #ffffff; //
$COL7: #1b1b1b; //
$COLFLAT7: #1b1b1b; //
$COL8: #f37736; //
$COLFLAT8: #f37736; //
$COL9: #0084ab; //
$COLFLAT9: #0084ab; //
$COL10: #0084ab; //
$COLFLAT10: #0084ab; //
$COL11: #1b1b1b; //
$COLFLAT11: #1b1b1b; //
$COL12: #0084ab; //
$COLFLAT12: #0084ab; //
$COL13: #0084ab; //
$COLFLAT13: #0084ab; //
$COL14: #0084ab; //
$COLFLAT14: #0084ab; //
$COL15: #0084ab; //
$COLFLAT15: #0084ab; //
$COL16: #0084ab; //
$COLFLAT16: #0084ab; //
$COL17: #1b1b1b; //
$COLFLAT17: #1b1b1b; //
$COL18: #1b1b1b; //
$COLFLAT18: #1b1b1b; //
$COL19: #1b1b1b; //
$COLFLAT19: #1b1b1b; //
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1248px,
  xxlarge: 1936px,
);
$global-width: 1200px;
