.logo {
  position: absolute;
  z-index: 9;
}

.me-Video figure {
  margin: 0 !important;
}

.homepage-content-wrap {
  margin-top: -70px;
}

.banner-text{
  position: absolute;
  top: 0;
  bottom: 0;
}

.me-MenuResp{
  z-index: 99;
}