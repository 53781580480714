body { background-color: $COL6; }
body { color: $COL7; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: $COL10;
font-family: 'Roboto';
font-weight: 500;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 25px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
color: $COL12;
font-family: 'Roboto';
font-weight: 500;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
color: $COL13;
font-family: 'Roboto';
font-weight: 500;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
color: $COL14;
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
color: $COL15;
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
color: $COL16;
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Roboto Condensed';
font-weight: normal;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
@media #{$large-up} {
font-size: 20px;

}
}
.me-Quote .quote-body {
font-family: 'Arapey';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 23px;

}
@media #{$large-up} {
font-size: 25px;

}
}
.me-Quote .quote-author {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: $COL18;
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
color: $COL17;
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
summary {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Roboto';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Roboto';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Roboto';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Roboto';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 20px;

}
body {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
p { padding-bottom: 1em; }
a {color: $COL8}
a:hover {color: $COL9}
/* Main Menu:12 */
nav.me-Menu.MES12 {
& .menu-item.MEC12, & .menu-item.MEC12 > div.MEC12{ & > a.MEC12{color: $COL3;
font-size: 14px;
@media #{$medium-up} {
font-size: 16.8px;
}@media #{$large-up} {
font-size: 18.2px;
}text-transform: uppercase;
}
 &:hover > a.MEC12{color: $COL2;
}
 }
&.horizontal > .menu-item.MEC12 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC12 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC12 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC12 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 10px;}

@media #{$large-up} {
& > .menu-item > a{padding: 54px 15px;}

}
& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
& .menu-item.MEC12, & .menu-item.MEC12 > div.MEC12{ & > a.MEC12{color: $COL6;
}
 &:hover > a.MEC12{color: $COL2;
}
 }

}
}
 }
/* Image outline:20 */
.MES20 {
padding: 0;

border-width: 1px;
border-style: solid;
border-color: $COL4;
 }
/* line:19 */
.MES19 {
padding: 15px;

& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: $COL4;}
& > hr {border-top-style: solid;}
 }
/* Page Background Image Panel:18 */
.MES18 {
background-color: $COL19;
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://owencustomproducts.com.au/img/42/28');
background-position: bottom center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}min-height:300px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 300px;};
 }
/* Form Style:17 */
.me-block.me-Form.MES17,  body.MES17 {background-color:transparent;
 color: $COL6;
 
 input[type='submit']{border-style: solid;background-color: $COL2;
&:hover {background-color: $COL19}
border-color: $COL1;
border-width: 1px;
color: $COL6;
font-size: 110%;
@media #{$medium-up} { font-size: 120%; };
@media #{$large-up} { font-size: 130%; };
padding: 10px 15px;

@media #{$medium-up} {
padding: 10px 20px;

}
@media #{$large-up} {
padding: 10px 30px;

}
border-radius: 50px;
}
@include placeholder($COL6);
input:not([type='submit']), textarea, select {border-style: solid;
border-width: 1px;
border-color: $COL6;
background-color: $COL1;
color: $COL6;
}
::-webkit-input-placeholder { color: $COL6; opacity: 0.5;}
:-moz-placeholder {color: $COL6; opacity: 0.5;} 
::-moz-placeholder {color: $COL6; opacity: 0.5;}
:-ms-input-placeholder {color: $COL6; opacity: 0.5;}

 .me-wrap-text a {color: $COL6;
} }
/* Footer area:16 */
.MES16 {
background-color: $COL3;
color: $COL6;
font-size: 12.6px;
@media #{$medium-up} {
font-size: 14.4px;
};
@media #{$large-up} {
font-size: 12.8px;
};
padding: 15px;

@media #{$large-up} {
padding: 20px 0;

}
 }
h1.MEC16, h2.MEC16, h3.MEC16, h4.MEC16, h5.MEC16, h6.MEC16 { color: $COL6;
 }
a.MEC16 { color: $COL6;
&:hover { color: $COL5; }
 }
cite.MEC16{
color: $COL6;
font-size: 12.6px;
@media #{$medium-up} {
font-size: 14.4px;
};
@media #{$large-up} {
font-size: 12.8px;
};
}
/* Button Orange:15 */
.MES15 {
background-color: $COL2;
&:hover { background-color: $COL19;}
color: $COL6;
&:hover { color: $COL6;}
border-radius: 50px;
padding: 10px 20px;

@media #{$medium-up} {
padding: 10px 15px;

}
border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Slider panel front:14 */
.MES14 {
color: $COL6;
font-size: 16.8px;
@media #{$medium-up} {
font-size: 19.2px;
};
@media #{$large-up} {
font-size: 22.4px;
};
 }
h1.MEC14, h2.MEC14, h3.MEC14, h4.MEC14, h5.MEC14, h6.MEC14 { color: $COL6;
 }
h1.MEC14 { @media #{$medium-up} { font-size: 48px; }; }
h1.MEC14 { @media #{$large-up} { font-size: 52px; }; }
h2.MEC14 { @media #{$medium-up} { font-size: 42px; }; }
h2.MEC14 { @media #{$large-up} { font-size: 45.5px; }; }
h3.MEC14 { @media #{$medium-up} { font-size: 36px; }; }
h3.MEC14 { @media #{$large-up} { font-size: 39px; }; }
h4.MEC14 { @media #{$medium-up} { font-size: 30px; }; }
h4.MEC14 { @media #{$large-up} { font-size: 32.5px; }; }
h5.MEC14 { @media #{$medium-up} { font-size: 24px; }; }
h5.MEC14 { @media #{$large-up} { font-size: 26px; }; }
h6.MEC14 { @media #{$medium-up} { font-size: 21.6px; }; }
h6.MEC14 { @media #{$large-up} { font-size: 23.4px; }; }
a.MEC14 { color: $COL6;
 }
cite.MEC14{
color: $COL6;
font-size: 16.8px;
@media #{$medium-up} {
font-size: 19.2px;
};
@media #{$large-up} {
font-size: 22.4px;
};
}
/* White Panel:13 */
.MES13 {
background-color: $COL6;
 }
/* Alternate:11 */
.MES11 {
background-color: $COL19;
 }
/* Secondary:2 */
.MES2 {
background-color: $COL2;
 }
/* Alternate:10 */
.MES10 {
background-color: $COL19;
 }
/* Dark:9 */
.MES9 {
background-color: $COL5;
 }
/* Dark:8 */
.MES8 {
background-color: $COL5;
 }
/* Light:7 */
.MES7 {
background-color: $COL4;
 }
/* Light:6 */
.MES6 {
background-color: $COL4;
 }
/* Primary:5 */
.MES5 {
background-color: $COL3;
color: $COL6;
 }
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: $COL6;
 }
cite.MEC5{
color: $COL6;
}
/* Primary:4 */
.MES4 {
background-color: $COL3;
 }
/* Secondary:3 */
.MES3 {
background-color: $COL2;
 }
/* Responsive Menu:21 */
nav.responsive-menu {
.menu-item.MEC21{background-color: $COL3;
}
& .menu-item.MEC21, & .menu-item.MEC21 > div.menu-item-wrap{ & > a.MEC21, & > i{color: $COL6;
text-align: left;
}
  }
& .menu-item.MEC21 { border:0;
border-color: $COL4;
border-style: solid;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 10px;}

& .sub-menu{.menu-item.MEC21{background-color: $COL2;
}
 .sub-menu {}}

 }
